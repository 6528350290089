import React, { Component, useState } from 'react';

import { ManagementServiceApi, postAPI } from './api';
import { AddProductMappingDialog } from "./add_product_mapping_dialog";
import { CoilDisplay, DexProduct, Product, ProductMapping, productMappingSorting, VendingMachine } from "./models";
import ProductMapRow from "./product_map_row";
import ImportProductMapping from "./import_product_mapping";
import { Default, Mobile } from "./components/responsive";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import formatUtilPrice from "./format_until_price";
import { TelemetryvendingMachinev1MotorCoupling } from "./telemetry/vending_machine/v1/typescript-axios";


interface Props extends IGlobalDataContext {
    vending_machine_uuid: string;
    product_list: Array<Product>;
    product_mappings: Array<ProductMapping>;
    coils: Array<DexProduct>; // this is used to alert users for inconsistency
    sample_selection: Array<CoilDisplay>;
    vending_machine: VendingMachine;

    handleRefresh(): void;

    format: string

    isMobile?: boolean

    motorCouplings: Array<TelemetryvendingMachinev1MotorCoupling>
}

interface State {
    show_adding_dialog: boolean
    format: string
    show_import_settings: boolean
    pictureFile
    pictureEncodedContent

    addingMotorCoupling: boolean
}

function AddingMotorCouplingDialog(props: {
    onUpdated: () => void,
    motorCouplings: Array<TelemetryvendingMachinev1MotorCoupling>,
    vendingMachineUUID: string,
    productMappings: Array<ProductMapping>,
}) {
    const [busy, setBusy] = useState(false);

    const validateCoupledMdbCode = (mainMdbCode:string, coupleCode:string) => {
        // 1. CoupleCode cannot be mdb_code that exists in productMappings
        const isExistingProductMapping = props.productMappings.some(
            (mapping) => getNumericMdbCode(mapping.mdb_code).toString() === coupleCode
        );

        // 2. CoupleCode cannot be mainMdbItemNumber or coupledMotorMdbItemNumber in motorCouplings
        const isExistingMotorCoupling = props.motorCouplings.some(
            (coupling) =>
                coupling.mainMdbItemNumber !== undefined &&
                getNumericMdbCode(coupling.mainMdbItemNumber).toString() === coupleCode &&
                coupling.coupledMotorMdbItemNumber !== undefined &&
                getNumericMdbCode(coupling.coupledMotorMdbItemNumber).toString() === coupleCode
        );

        // 3. coupleCode and mainMdbCode cannot be the same
        const isCoupleCodeSameAsMainMdbCode = coupleCode === mainMdbCode;

        // 4. Neither coupleCode nor mainMdbCode can be 9999
        const isSpecialNumber = (mainMdbCode === '9999' || coupleCode === '9999');

        // 5. The length of coupleCode and mainMdbCode must be the same
        const isValidLength = mainMdbCode.length === coupleCode.length;

        // 6. The first three digits of coupleCode and mainMdbCode must be the same
        const isValidateSamePrefix = validateSamePrefix(
            mainMdbCode,
            coupleCode,
        );

        // 7.Find the positions of mainMdbCode and coupleCode in the mdbCode in productMappings and compare them.
        const isValidMainMdbCodeWithProductMappings = validateMainMdbCodeWithProductMappings(
            mainMdbCode,
            coupleCode,
            props.productMappings
        );

        return {
            isExistingProductMapping,
            isExistingMotorCoupling,
            isCoupleCodeSameAsMainMdbCode: isCoupleCodeSameAsMainMdbCode,
            isValidLength,
            isValidMainMdbCodeWithProductMappings,
            isSpecialNumber: isSpecialNumber,
            isValidateSamePrefix: isValidateSamePrefix
        };
    };

    const validateSamePrefix = (mainMdbCode:string, coupleCode:string) => {
        if (coupleCode.length === 1 && mainMdbCode.length === 1) {
            return true;
        }

        const prefixLength = Math.min(coupleCode.length - 1, mainMdbCode.length - 1);
        const prefix1 = coupleCode.slice(0, prefixLength);
        const prefix2 = mainMdbCode.slice(0, prefixLength);
        return prefix1 === prefix2;
    };



    const validateMainMdbCodeWithProductMappings = (mainMdbCode: string, coupleCode: string, productMappings: ProductMapping[]) => {
        const currentIndex = productMappings.findIndex(mapping => {
            return getNumericMdbCode(mapping.mdb_code) === getNumericMdbCode(mainMdbCode);
        });

        // 7.1.It won’t work if mainMdbCode is not found.
        if (currentIndex === -1) return false;

        // 7.2 If the first mainMdbCode is found, then the coupleCode must be smaller than the second mainMdbCode.
        if (currentIndex === 0) {
            const next = productMappings[1];
            if (getNumericMdbCode(coupleCode) > getNumericMdbCode(next.mdb_code)) {
                return false;
            }
        }

        // 7.3 If the last digit of mainMdbCode is found, then the coupleCode must be larger than the penultimate mainMdbCode.
        if (currentIndex === productMappings.length - 1) {
            const previous = productMappings[currentIndex - 1];
            if (getNumericMdbCode(coupleCode) < getNumericMdbCode(previous.mdb_code)) {
                return false;
            }
        }

        // 7.4 If the mainMdbCode found is not the first one, the coupleCode must be larger than the previous mdbCode.
        if (currentIndex > 0) {
            const previous = productMappings[currentIndex - 1];
            if (getNumericMdbCode(coupleCode) < getNumericMdbCode(previous.mdb_code)) {
                return false;
            }
        }

        // 7.5 If the found mainMdbCode is not the last one, the coupleCode needs to be smaller than the next one.
        if (currentIndex < productMappings.length - 1) {
            const next = productMappings[currentIndex + 1];
            if (getNumericMdbCode(coupleCode) > getNumericMdbCode(next.mdb_code)) {
                return false;
            }
        }

        return true;

    }

    function getNumericMdbCode(code: string) {
        return parseInt(code, 10); 
    }


    const updateMotorCoupling = (e) => {
        e.preventDefault();

        const normalizedMainMdbCode = getNumericMdbCode(mainMdbCode).toString();
        const normalizedCoupledMdbCode = getNumericMdbCode(coupledMdbCode).toString();
        const validations = validateCoupledMdbCode(normalizedMainMdbCode, normalizedCoupledMdbCode);

        if (
            !validations.isExistingMotorCoupling &&
            !validations.isExistingProductMapping &&
            !validations.isCoupleCodeSameAsMainMdbCode &&
            validations.isValidLength &&
            validations.isValidMainMdbCodeWithProductMappings &&
            !validations.isSpecialNumber &&
            validations.isValidateSamePrefix
        ) {
            setBusy(true);

            new ManagementServiceApi().machineManagementServiceUpdateMotorCouplings(props.vendingMachineUUID, {
                motorCouplings: [{
                    coupledMotorMdbItemNumber: getNumericMdbCode(coupledMdbCode).toString(),
                    mainMdbItemNumber: getNumericMdbCode(mainMdbCode).toString()
                }, ...props.motorCouplings]
            }).then((resp) => {
                if (resp.status === 200) {
                    props.onUpdated();
                } else {
                    window.alert("Failed to add motor coupling")
                }
            }).finally(() => {
                setBusy(false);
            });
        } else {
            if (validations.isExistingMotorCoupling) {
                window.alert("Main MDB Code cannot be an existing motor coupling main MDB code");
                return
            }
            if (validations.isExistingProductMapping) {
                window.alert("Coupled MDB Code cannot be an existing product mapping MDB code");
                return
            }
            if (validations.isCoupleCodeSameAsMainMdbCode) {
                window.alert("Coupled MDB Code cannot be the same as Main MDB Code in a motor coupling");
                return
            }
            if (!validations.isValidLength) {
                window.alert("Main MDB Code must have the same number of digits as Coupled MDB Code.");
                return
            }
            if (!validations.isValidMainMdbCodeWithProductMappings) {
                window.alert("CoupleCode cannot jump already existing MDBCode association");
                return
            }
            if (validations.isSpecialNumber) {
                window.alert("MDBCode or CoupleCode cannot be a special number");
                return
            }
            if (!validations.isValidateSamePrefix) {
                window.alert("The first three digits of Coupled Code and mdb Code need to be the same");
                return
            }
        }
    }

    const [mainMdbCode, setMainMdbCode] = useState<string>('0');
    const [coupledMdbCode, setCoupledMdbCode] = useState<string>('0');

    const updateMainMdbCode = (e) => {
        const inputValue = e.target.value === '' ? '0' : e.target.value.trim();

        if (/^\d{0,4}$/.test(inputValue)) {
            setMainMdbCode(inputValue);
        }
    }

    const updateCoupledMdbCode = (e) => {
        const inputValue = e.target.value === '' ? '0' : e.target.value.trim();
        if (/^\d{0,4}$/.test(inputValue)) {
            setCoupledMdbCode(inputValue);
        }
    }

    return <div>
        <br />
        <form onSubmit={updateMotorCoupling} className={"form"}>
            <label>Select template vending machine</label><br />&nbsp;

            <label>Main MDB Item Number:</label><input onChange={updateMainMdbCode} className={"form-control"} type={"text"} value={mainMdbCode}
                placeholder={"Main MDB Code"} />
            <label>Coupled MDB Item Number:</label><input onChange={updateCoupledMdbCode} className={"form-control"} type={"text"} value={coupledMdbCode}
                placeholder={"Coupled MDB Code"} />

            <br />
            {busy ?
                (<i className="fas fa-cog fa-spin"></i>)
                :
                (<input type={"submit"} value={"Save"} className={"btn btn-success"} />)
            }
        </form>
    </div>
}

class VendingMachineProductMappingPanelRaw extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            show_import_settings: false,
            show_adding_dialog: false,
            format: this.props.format,
            pictureFile: null,
            pictureEncodedContent: "",
            addingMotorCoupling: false
        }
    }

    importMappings(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ show_import_settings: !this.state.show_import_settings })
    }

    handleFormatChange(event) {
        event.preventDefault();
        postAPI(`/api/vending_machines/${this.props.vending_machine_uuid}/display_format`, { format: this.state.format })
            .then((response) => {
                if (response.data.success) {
                    this.props.handleRefresh();
                } else {
                    window.alert(response.data.message);
                }
            });
        return false;
    }

    handle_selection_change(event) {
        this.setState({ format: event.target.value });
    }

    updateProductMapping(mapping: ProductMapping): Promise<any> {
        let m = { ...mapping };
        return postAPI(`/api/product_mappings/${mapping.id}`, m).then((response) => {
            if (response.data.success) {
                this.props.handleRefresh();
                return response;
            } else {
                window.alert(response.data.message);
            }
        });
    }

    add_product_mapping(e) {
        e.preventDefault();

        this.setState({
            show_adding_dialog: true,
        });

        return false;
    }

    handleFormSubmit(e, productMapping: ProductMapping) {
        if (this.props.product_mappings.filter(mapping => {
            return (mapping.product_id === productMapping.product_id && mapping.price !== productMapping.price);
        }).length > 0) {
            if (!window.confirm(`Prices of this product vary. Are you sure to save it?`)) {
                return;
            }
        }
        e.preventDefault();
        postAPI('/api/product_mapping/', {
            vendingMachineUuid: this.props.vending_machine_uuid,
            productId: productMapping.product_id,
            mdbCode: productMapping.mdb_code,
            code: productMapping.code,
            price: productMapping.price,
            capacity: productMapping.capacity,
            restock: productMapping.restock,
            originalPrice: productMapping.original_price,
        }).then((result) => {
            if (result.data.success) {
                this.setState({ show_adding_dialog: false });
                this.props.handleRefresh();
            } else {
                alert(result.data.message);
            }
        })

    }

    handleRemove(id) {
        if (window.confirm("Confirm delete?")) {
            postAPI('/api/product_mapping/remove', {
                vendingMachineUUID: this.props.vending_machine_uuid,
                id: id
            }).then((response) => {
                if (response.data.success) {
                    this.props.handleRefresh();
                } else {
                    alert(response.data.message);
                }
            })
        }
    }

    handleFix(id) {
        if (window.confirm("Ack coil failure? If you enabled prevent purchase from failed coil feature in company settings, this action will resume purchase from this coil.")) {
            postAPI(`/api/product_mapping/${id}/fix_faulty_coil`, {
                id: id
            }).then((response) => {
                if (response.data.success) {
                    this.props.handleRefresh();
                } else {
                    alert(response.data.message);
                }
            })
        }
    }

    handleOnSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            console.log(e.target.files[0]);
            this.setState({ pictureFile: e.target.files[0] })
        } else {
            this.setState({ pictureFile: null })
        }

        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent = fileReader.result;
            if (typeof pictureContent === "string") {
                this.setState({ pictureEncodedContent: window.btoa(pictureContent) })
            }
        };
        fileReader.readAsBinaryString(e.target.files[0]);
    }

    uploadPhoto(e) {
        e.preventDefault();
        postAPI(`/api/product_mapping/${this.props.vending_machine_uuid}/photo`, { imageContent: this.state.pictureEncodedContent }).then((response) => {
            if (response.data.success) {
                alert("Planogram upload successful: " + response.data.message)
            }
        })
    }

    syncFourPrice(e) {
        e.preventDefault();
        postAPI(`/api/vending_machines/${this.props.vending_machine_uuid}/sync_four_price`, {}).then((response) => {
            alert(response.data.message)
        })
    }

    render() {

        /*
                            <th>Product Name</th>
                            <th>Code (DEX)</th>
                            <th>MDB Code</th>
                            <th>Price</th>
                            <th>Capacity</th>
                            <th>Restock Count</th>
                            <th>Operations</th>
         */
        let product_mappings = this.props.product_mappings.sort(productMappingSorting).map((mapping: ProductMapping) => {
            return (<ProductMapRow key={mapping.id}
                coils={this.props.coils}
                all_products={this.props.product_list}
                handleRemove={() => {
                    this.handleRemove(mapping.id)
                }}
                motorCouplings={this.props.motorCouplings}
                allProductMappings={this.props.product_mappings}
                updateProductMapping={this.updateProductMapping.bind(this)}
                mapping={mapping}
                handleFix={() => {
                    this.handleFix(mapping.id)
                }}
                handleRefresh={this.props.handleRefresh}
                vendingMachine={this.props.vending_machine} isMobile={!!this.props.isMobile} />);
        });

        let additional_dex_product_rows: Array<any> | null = null;

        if (this.props.coils) {
            /* additional dex products: store products that do not exist in our system */
            let additional_dex_products = this.props.coils.filter((dexProduct): boolean => {
                for (let pm of this.props.product_mappings) {
                    if (pm.code === dexProduct.identifier) {
                        return false;
                    }
                }

                return true;
            });

            additional_dex_product_rows = additional_dex_products.map((dexProduct) => {
                return (
                    <tr key={dexProduct.identifier}>
                        <td>{dexProduct.identifier}</td>
                        <td>{dexProduct.identification}</td>
                        <td>${dexProduct.price ? formatUtilPrice(dexProduct.price, this.props.me.mostRecentCompany.currencyDecimalPoints) : '-'}</td>
                    </tr>
                )
            });
        }

        let adding_dialog: any = null;

        if (this.state.show_adding_dialog) {
            adding_dialog = (<AddProductMappingDialog
                hide_dialog={() => {
                    this.setState({ show_adding_dialog: false })
                }}
                product_list={this.props.product_list}
                company_info={this.props.me.mostRecentCompany}
                onSubmit={this.handleFormSubmit.bind(this)}/>);

        } else {
            adding_dialog = (
                <button onClick={this.add_product_mapping.bind(this)} className="btn btn-info">Add a Product
                    Mapping</button>);
        }

        const addMotorCoupling = (e) => {
            e.preventDefault();

            this.setState({ addingMotorCoupling: !this.state.addingMotorCoupling })
        }

        return (
            <div className="box">
                <div className="box-body">
                    <h3>Product Mapping</h3>
                    <div className={"row"}>
                        <div className={"col-xs-12"}>
                            <div className="box box-success well">
                                <div className={"box-header"}>
                                    <h4>Planogram Photo</h4>
                                </div>
                                <div className={"box-body"}>
                                    <form onSubmit={this.uploadPhoto.bind(this)}>
                                        <div className="form-group">
                                            <input type="file" onChange={this.handleOnSelectFile.bind(this)}
                                                className={"form-control"} />
                                        </div>
                                        <div className="form-group">
                                            <input type="submit" className="btn btn-success" value="Upload" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}>
                            {this.props.vending_machine.vendingMachineType !== "FOURPRICE" &&
                                <div className="box box-success well">
                                    <div className={"box-header"}>
                                        <h4>Add new Product Mapping</h4>

                                        <div>
                                            This form allows vending machine operator to add product mapping even
                                            without
                                            sales data for that coil.
                                        </div>
                                    </div>
                                    <div className={"box-body"}>
                                        {adding_dialog}
                                        <hr />
                                        <p>Alternatively, you can <b>import</b> product mappings from another vending
                                            machine</p>
                                        <button className="btn btn-success" onClick={this.importMappings.bind(this)}>
                                            Import product mappings from another vending machine
                                        </button>
                                        <br />
                                        {this.state.show_import_settings &&
                                            <ImportProductMapping
                                                vendingMachineUUID={this.props.vending_machine_uuid}
                                                onUpdated={this.props.handleRefresh}
                                            />
                                        }

                                        <hr />
                                        <button className={"btn btn-info"} onClick={addMotorCoupling}>
                                            Add Motor Coupling <i className={"fa fas fa-lock"} />
                                        </button>
                                        {this.state.addingMotorCoupling && <AddingMotorCouplingDialog
                                            vendingMachineUUID={this.props.vending_machine_uuid}
                                            motorCouplings={this.props.motorCouplings}
                                            onUpdated={this.props.handleRefresh}
                                            productMappings={this.props.product_mappings}
                                        />}
                                    </div>
                                </div>}
                            {this.props.vending_machine.vendingMachineType === "FOURPRICE" &&
                                <div className={"box box-success"}>
                                    <div className={"box-header"}>
                                        <h4>Sync price with telemetry</h4>
                                    </div>

                                    <div className={"box-body"}>
                                        <label>Click the button below to sync the price with vending machine</label>
                                        <form onSubmit={this.syncFourPrice.bind(this)}>
                                            <input type="submit" className={"btn btn-success"} value={"Sync Price"} />
                                        </form>
                                    </div>
                                </div>}
                        </div>

                        <div className={"col-xs-6"}>
                            <div className={"box box-success"}>
                                <div className={"box-header"}>
                                    <h4>Selection display settings</h4>
                                </div>

                                <div className={"box-body"}>
                                    <label>Which option below describes the display the best?</label>
                                    {this.props.sample_selection ?
                                        (<form onSubmit={this.handleFormatChange.bind(this)}>
                                            <select className={"form-control"}
                                                onChange={this.handle_selection_change.bind(this)}
                                                value={this.state.format}>
                                                {this.props.sample_selection.map((item) => {
                                                    return <option key={item.format}
                                                        value={item.format}>{item.coilDisplayResult}</option>
                                                })}
                                            </select>

                                            <input type={"submit"} className={"btn btn-success"} />
                                        </form>)
                                        :
                                        null}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!this.props.isMobile && <table className="table table-bordered table-hover">
                        <tbody>
                            <tr>
                                <th className="col-xs-2">Product Name</th>
                                <th className="col-xs-1">Code (DEX)</th>
                                <th className="col-xs-1">MDB Code</th>
                                <th className="col-xs-1">Capacity</th>
                                <th className="col-xs-1">Restock Count</th>
                                <th className="col-xs-2">Price</th>
                                <th className='col-xs-2'>Original Price</th>
                                <th className="col-xs-2">Operations</th>
                            </tr>

                            {product_mappings}
                        </tbody>
                    </table>}
                    {this.props.isMobile && <table className="table table-bordered table-hover">
                        <tbody>
                            <tr>
                                <th>Product Name</th>
                                <th>Capacity</th>
                                <th>Restock Count</th>
                                <th>Price</th>

                                <th>Operations</th>
                            </tr>

                            {product_mappings}
                        </tbody>
                    </table>}

                    <hr />

                    <h3>Missing entries</h3>

                    <table className="table table-bordered table-hover">
                        <tbody>
                            <tr>
                                <th>Code</th>
                                <th>Identification</th>
                                <th>Price</th>
                            </tr>

                            {additional_dex_product_rows}
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }
}


export const VendingMachineProductMappingPanel = withGlobalData((props: Props) => (
    <div>
        <Mobile>
            <VendingMachineProductMappingPanelRaw isMobile={true} {...props} />
        </Mobile>
        <Default>
            <VendingMachineProductMappingPanelRaw isMobile={false} {...props} />
        </Default>
    </div>
));
